@import "./variables.less";

// brand
@brand-primary:        #685D9F;


// misc typography
@headings-font-weight: 100;
@btn-font-weight:      300;
@letter-spacing-base:  -.02em;


// lead
@lead-letter-spacing: @letter-spacing-base;
@lead-font-size:      floor((@font-size-base * 1.5));


// blocks
@block-title-font-size-sm: floor((@font-size-base * 5.2));
@block-title-font-size:    floor((@font-size-base * 4));
@block-lead-font-size-sm:  floor((@font-size-base * 1.5));
@block-bg-inverted:        #222222;
@block-color-inverted:     #d0d0d0;


// navbars
@navbar-default-toggle-border-color: transparent;
@navbar-default-toggle-hover-bg:     transparent;
@navbar-default-border:              transparent;
@navbar-default-brand-color:         @gray-dark;

@nav-bordered-color:              #000;
@nav-bordered-color-active:       @brand-primary;
@nav-bordered-font-weight:        300;
@nav-bordered-font-weight-active: 700;

@input-height-base: 39px;

@import "./components.less";

body.adblock {
    .visible-adblock {
        display: inherit;
    }

    .hide-adblock {
        display: none;
    }
}

body.no-adblock {
    .visible-adblock {
        display: none;
    }

    .hide-adblock {
        display: inherit;
    }
}

.tm-click-to-tweet {
    border: 1px solid #ddd;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 15px 30px;
    margin: 15px 0px;
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    background-color: white;

    .tm-ctt-text {
        text-align: left;
        font-family: "museo-sans", sans-serif;
        font-weight: 300;
        margin-bottom: 10px;

        a {
            color: #aeaeae;
            font-size: 22px;
            line-height: 140%;
            letter-spacing: 0.05em;
            font-weight: 100;
            text-decoration: none;
            text-transform: none;

            &:hover {
                text-decoration: none;
                color: #aeaeae;
            }
        }
    }

    a.tm-ctt-btn {
        display: block;
        text-transform: uppercase;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 100%;
        color: #55acee;
        float: right;
        padding-right: 0;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: #666;
            text-transform: uppercase;
        }
    }
}

@media (max-width:767px) {
    .tm-click-to-tweet {
        margin-top: 40px;

        .tm-ctt-text a {
            font-size: 22px;
        }
    }
}
